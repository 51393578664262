<template>
  <div class="main-body">
    <div id="nav-white" />
    <div id="policy">
      <div class="d-center fadeIn">
        <div class="body-policy">
          <h1 class="title-md">Refund <span>policy</span></h1>
          <p>
            Please read these Terms and Conditions carefully before using the
            services offered by GoBuilders Netsoft Sdn Bhd (“GoLog“) or (“We”,
            “Us”, or “Our”), a company incorporated under the laws of Malaysia.
            These terms set forth the legally binding terms and conditions for
            your use of Our Services as contained in http://www.glogc.com
            (“Website”).
          </p>

          <div>
            <ol class="styled-list">
              <li>
                <h4 class="">COMPENSATION OF YOUR GOODS</h4>
                <ol type="a">
                  <li>
                    We do not accept any return of your items and We do not
                    replace any of your items. We will endeavour all reasonable
                    care to ensure that Our Services conform to the standard
                    delivery care of any delivery services in Malaysia.
                  </li>
                  <li>
                    In case of any damages and theft of items, please inform
                    GoLog within three (3) hours and take necessary steps, e.g.,
                    police report on the theft of items, GoLog will only be able
                    to provide data of the GoLoger if it is required by Law.
                  </li>
                  <li>
                    Please ensure that you have packaged and clearly marked any
                    fragile or valuable in a manner adequate to withstand the
                    risk of damage or deterioration while in transportation,
                    loading and unloading. GoLog shall not be liable for any
                    loss/damage to these goods arising out of or resulting from
                    your failure to perform such obligations.
                  </li>
                </ol>
              </li>
              <li>
                <h4>CANCELLATION</h4>
                <p>
                  A "Cancellation Fee" which is RM5 of the agreed contractual
                  fee will be applied in the following circumstances:
                </p>
                <ol type="a">
                  <li>
                    If fifteen (15) minutes have elapsed since the User
                    requested Service and has been accepted by the GoLoger, and
                    then the User cancels the request, or
                  </li>
                  <li>
                    The User requested Service and the GoLoger discovers and
                    accepts, upon GoLoger arriving at requested pickup location,
                    that the User Items are not eligible for the Services.
                  </li>
                  <li>
                    For clarity, if, before fifteen (15) minutes upon GoLoger
                    accepting the service request, the User cancels the request,
                    no cancellation fee will apply.
                  </li>
                  <li>
                    However, if the service paid was not fulfilled due to
                    factors beyond GoLog's control, we will offer an alternative
                    or you will be entitled to a full refund of paid amount for
                    that specific service.
                  </li>
                  <li>
                    We will refund your purchase of Our Services and a credit
                    will be applied to your original method of payment or any
                    such manner as may be communicated to you.
                  </li>
                </ol>
              </li>
              <li>
                <h4>DAMAGED GOODS</h4>
                <ol type="a">
                  <li>
                    GoLog will not be liable for any damage, loss or
                    deterioration of goods. As User and GoLoger, you understand
                    that it is on your sole discretion to use our Services and
                    you are responsible for any damage of items. GoLoger will
                    have the right to not accept the delivery request by stating
                    the reason as “damaged parcel” and Users will have no right
                    to force GoLoger to accept the delivery of this situation.
                  </li>
                  <li>
                    GoLoger will examine any defects of your item prior to the
                    loading of your items for transportation, hence we will not
                    be liable for any unnoticed defect that is not informed by
                    you.
                  </li>
                  <li>
                    Please allow GoLoger to take a picture of the goods received
                    and do inform the "Receiver" to sign off the goods received,
                    with a picture of themselves accepting the items in good
                    condition. Any items signed off will not be borned liable by
                    GoLoger and GoLog.
                  </li>
                </ol>
              </li>
              <li>
                <h4>DELIVERY OF GOODS</h4>
                <ol type="a">
                  <li>
                    We endeavour to adhere to stipulated delivery deadlines as
                    indicated in Our Website. However, due to the hazards and
                    peculiar features of Our Services, delivery deadlines will
                    not be binding unless expressly agreed otherwise.
                  </li>
                  <li>
                    Furthermore, actual charges may vary based on factors
                    outside of the Company's control, such as but not limited to
                    weather and traffic conditions, store loading and wait
                    times, customer special requests, and other unexpected
                    barriers to loading or unloading that may or may not have
                    been communicated to GoLog.
                  </li>
                  <li>
                    GoLogers will send immediately the goods from the pick-up
                    location at the designated time. The delivery status and
                    "Live Tracking" will be available to you once the GoLoger
                    accepted the request.
                  </li>
                  <li>
                    Our contractual obligations are subject to a case-to-case
                    basis based on your request and additional charges may
                    incur.
                  </li>
                </ol>
              </li>
              <li>
                <h4>LOGPOINTS</h4>
                <p>
                  Ensure complete utilization of all log points and
                  non-refundable. Any request for a refund submitted after
                  October 1, 2023 will be rejected and will not be eligible for
                  processing. Additionally, accounts that have been inactive for
                  one year will be subject to forfeiture of any remaining
                  logpoint.
                </p>
              </li>
              <li>
                <h4>NO WAIVER</h4>
                <p>
                  The failure to exercise or delay in exercising a right or
                  remedy provided hereunder or by law does not constitute a
                  waiver of the right or remedy or waiver of other rights or
                  remedies.
                </p>
              </li>
              <li>
                <h4>GOVERNING LAW</h4>
                <p>
                  These Terms and Conditions are not intended to nor shall
                  create any rights, entitlements, claims or benefits
                  enforceable by any person that is not a party to them. No
                  person shall derive any benefit or have any right, entitlement
                  or claim in relation to this Terms and Conditions.
                </p>
              </li>
              <li>
                <h4>SPECIAL TERMS</h4>
                <p>
                  These Terms and Conditions are not intended to nor shall
                  create any rights, entitlements, claims or benefits
                  enforceable by any person that is not a party to them. No
                  person shall derive any benefit or have any right, entitlement
                  or claim in relation to this Terms and Conditions.
                </p>
              </li>
              <li>
                <h4>CHANGES TO THE TERMS AND CONDITIONS</h4>
                <p>
                  We reserve the right to update these Terms and Conditions at
                  any time and any changes may be made without notice to you. We
                  recommend you to read this Terms and Conditions periodically.
                  <br /><br />
                  By continuing to access or use Our Services after those
                  revisions or changes become effective, you agree to be bound
                  by the revised terms. If you do not agree to the new Terms and
                  Conditions, please stop using the Website and/or Our Services.
                  <br /><br /><br />
                  All of our rights are reserved.
                </p>
              </li>
              <!-- <li>
              <h4></h4>
              <p></p>
            </li> -->
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import intersectionObserverMixin from "@/mixins/intersectionObserverMixin";

export default {
  mixins: [intersectionObserverMixin],
  mounted() {
    this.setupIntersectionObserver(".fadeIn", 0);
  },
};
</script>
